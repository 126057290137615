import React, { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Grid, Typography, Paper, TextField, Box, LinearProgress, Avatar, CircularProgress, Button } from '@mui/material';
import axios from 'axios';
import moment from 'moment-timezone';
import DownloadPdfButton from "./PDFButton";
import logo from '../static/logo-3.png';
import dentistImage from '../static/ellipse-104.png';
import EmptyPage from './404';
import tooth from './Tooth.png';

export const ReportPage = () => {
  const { id } = useParams();
  const [severity, serSeverity] = useState([]);
  const [diagnosesArray, setDiagnosesArray] = useState([]);
  const [treatmentsArray, setTreatmentsArray] = useState([]);
  const [productsArray, setProductsArray] = useState([]);
  const [imagesArray, setImagesArray] = useState([]);
  const [dentistComment, setDentistComment] = useState('');
  const [problemDescription, setProblemDescription] = useState('');
  const [patientName, setPatientName] = useState('');
  const [patientEmail, setPatientEmail] = useState('');
  const [patientPhone, setPatientPhone] = useState('');
  const [serviceType, setServiceType] = useState('');
  const [date, setDate] = useState('');
  const [loading, setLoading] = useState(true);
  const contentRef = useRef(null);
  const [isVerified, setIsVerified] = useState(() => {
    const savedVerification = localStorage.getItem(`report_${id}_verification`);
    if (savedVerification) {
      const { timestamp, email } = JSON.parse(savedVerification);
      if (Date.now() - timestamp < 24 * 60 * 60 * 1000) {
        return true;
      } else {
        localStorage.removeItem(`report_${id}_verification`);
      }
    }
    return false;
  });
  const [userEmail, setUserEmail] = useState('');
  const [emailError, setEmailError] = useState(false);

  const verifyEmail = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/reports/${id}`);
      const fetchedCase = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/cases/${response.data.PatientID}`);

      if (fetchedCase.data.user_email.toLowerCase() === userEmail.toLowerCase()) {
        setIsVerified(true);
        setEmailError(false);

        localStorage.setItem(`report_${id}_verification`, JSON.stringify({
          timestamp: Date.now(),
          email: userEmail.toLowerCase()
        }));
      } else {
        setEmailError(true);
      }
    } catch (error) {
      console.error("Error verifying email:", error);
      setEmailError(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/reports/${id}`);
        const fetchedReport = response.data;

        // Set individual state variables
        setProblemDescription(fetchedReport.ProblemDescription);
        setDentistComment(fetchedReport.DentistComment);

        // Extract severity array
        const severityArray = fetchedReport.ImageDetails.map(imageDetail => {
          return imageDetail.Diseases.map(disease => disease.SeverityScale);
        });
        serSeverity(severityArray.flat());

        const fetchedCase = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/cases/${fetchedReport.PatientID}`);
        if (fetchedCase.data) {
          setPatientName(fetchedCase.data.user_name);
          setPatientEmail(fetchedCase.data.user_email);
          setPatientPhone(fetchedCase.data.user_phone_no);
          const dateObj = moment.tz(fetchedCase.data.createdAt, 'YYYY-MM-DDTHH:mm:ss.SSS[Z]');
          const formattedDate = dateObj.format('DD MMM YYYY');
          setDate(formattedDate);
          setServiceType(fetchedCase.data.coverage_type);
        }

        // Extract diagnosis array
        const diagnosisArray = await Promise.all(fetchedReport.ImageDetails.map(async imageDetail => {
          const diseaseId = imageDetail.Diseases[0].DiseaseID;
          if (diseaseId) {
            const diseaseResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/diagnoses/${diseaseId}`);
            return {
              name: diseaseResponse.data.Name,
              description: diseaseResponse.data.Description,
              treatmentsNumber: imageDetail.Diseases[0].Treatments.length,
              productsNumber: imageDetail.Diseases[0].Products.length
            };
          }
        }));

        setDiagnosesArray(diagnosisArray);


        const imageArray = await Promise.all(fetchedReport.ImageDetails.map(async imageDetail => {
          if (imageDetail.ImageID) {
            const imageResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/images/${imageDetail.ImageID}`);
            return imageResponse.data;
          }
        }));
        setImagesArray(imageArray);


        // Extract treatment array
        const treatmentsArray = fetchedReport.ImageDetails.map(imageDetail => {
          return imageDetail.Diseases.map(disease => {
            return disease.Treatments.map(async treatmentId => {
              const treatmentResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/treatments/${treatmentId}`);
              return treatmentResponse.data;
            });
          });
        });
        setTreatmentsArray((await Promise.all(treatmentsArray.flat().flat())).flat());

        // Extract product array
        const productsArray = fetchedReport.ImageDetails.map(imageDetail => {
          return imageDetail.Diseases.map(disease => {
            return disease.Products.map(async productId => {
              const productResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/api/products/${productId}`);
              return productResponse.data;
            });
          });
        });
        setProductsArray((await Promise.all(productsArray.flat().flat())).flat());

      } catch (error) {
        console.error("Error fetching report:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [id]);


  return loading ? (
    <Grid container justifyContent="center" alignItems="center" style={{ minHeight: '100vh' }}>
      <CircularProgress />
    </Grid>
  ) : !isVerified ? (
    <Container maxWidth="sm" sx={{ mt: { xs: 4, md: 8 }, px: 2 }}>
      <Paper elevation={3} sx={{ p: { xs: 2, md: 4 } }}>
        <Typography variant="h5" gutterBottom align="center">
          Please Verify Your Email
        </Typography>
        <form onSubmit={verifyEmail}>
          <TextField
            fullWidth
            label="Enter your email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            error={emailError}
            helperText={emailError ? "Invalid email. Access denied." : ""}
            sx={{ mt: 2 }}
          />
          <Button
            fullWidth
            variant="contained"
            type="submit"
            sx={{ mt: 2, bgcolor: '#B07FE5' }}
          >
            Access Report
          </Button>
        </form>
      </Paper>
    </Container>
  ) : diagnosesArray.length > 0 ? (
    <div ref={contentRef}>
      <Container maxWidth="lg" sx={{ px: { xs: 1, sm: 2, md: 3 } }}>
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Paper
              sx={{
                background: "#B07FE5",
                color: "#FFFFFF",
                position: "relative",
                padding: { xs: '1rem', sm: '1.5rem', md: '2rem' },
                borderRadius: 0,
                overflow: 'hidden'
              }}
              elevation={0}
            >
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                {/* Logo Section */}
                <Grid item xs={6} md={4}>
                  <img
                    src={logo}
                    alt="Logo"
                    style={{
                      height: 'auto',
                      width: '100%',
                      maxHeight: '80px',
                      objectFit: 'contain'
                    }}
                  />
                </Grid>

                {/* Slogan Section */}
                <Grid item xs={6} md={8}>
                  <Typography
                    sx={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: { xs: "10px", sm: "12px", md: "16px" },
                      fontStyle: "italic",
                      fontWeight: "500",
                      opacity: "0.8",
                      textAlign: { xs: 'right', md: 'right' }
                    }}
                  >
                    Your Online Dentist Anytime, Anywhere
                  </Typography>
                </Grid>
              </Grid>

              {/* Title and Subtitle Section */}
              <Grid container spacing={1} sx={{ mt: { xs: 1, md: 2 } }}>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontSize: { xs: "24px", sm: "30px", md: "40px" },
                      fontWeight: "700",
                      textTransform: "uppercase",
                      lineHeight: 1.2
                    }}
                  >
                    Patient <span style={{ fontWeight: "900" }}>Report</span>
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontFamily: "Plus Jakarta Sans",
                      fontSize: { xs: "16px", sm: "18px", md: "24px" },
                      fontWeight: "800",
                      textTransform: "uppercase",
                      color: "#FFFF8F",
                    }}
                  >
                    Dental Care
                  </Typography>
                </Grid>
              </Grid>

              {/* Background Tooth Image */}
              <img
                src={tooth}
                alt="Background"
                style={{
                  position: "absolute",
                  bottom: "0",
                  right: "0",
                  height: '40%',
                  width: "auto",
                  opacity: 0.5,
                  pointerEvents: 'none'
                }}
              />
            </Paper>
          </Grid>

          {/* Patient Info Section */}
          <Grid item xs={12} md={12} lg={8}>
            <Grid container spacing={2} sx={{ mt: 2 }}>
              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ p: { xs: 1, md: 2 } }}>
                  <Typography
                    sx={{
                      color: '#FFA70B',
                      fontFamily: 'Plus Jakarta Sans',
                      fontWeight: '800',
                      fontSize: { xs: '12px', md: '16px' },
                    }}
                    gutterBottom
                  >
                    PATIENT INFO
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: '12px', md: '16px' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '700',
                        color: '#FFA70B',
                        fontFamily: 'Plus Jakarta Sans',
                      }}
                    >
                      Name:
                    </span>{' '}
                    {patientName}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: '12px', md: '16px' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '700',
                        color: '#FFA70B',
                        fontFamily: 'Plus Jakarta Sans',
                      }}
                    >
                      Email:
                    </span>{' '}
                    {patientEmail}
                  </Typography>
                  <Typography
                    variant="body1"
                    gutterBottom
                    sx={{
                      fontSize: { xs: '12px', md: '16px' },
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    <span
                      style={{
                        fontWeight: '700',
                        color: '#FFA70B',
                        fontFamily: 'Plus Jakarta Sans',
                      }}
                    >
                      Phone:
                    </span>{' '}
                    {patientPhone}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} sm={6} md={4}>
                <Box sx={{ p: { xs: 1, md: 2 } }}>
                  <Typography sx={{ color: '#FFA70B', fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' } }} gutterBottom>
                    SERVICE
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                    <span style={{ fontWeight: '700', color: '#FFA70B', fontFamily: 'Plus Jakarta Sans' }}>Type:</span> {serviceType}
                  </Typography>
                  <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '16px' } }}>
                    <span style={{ fontWeight: '700', color: '#FFA70B', fontFamily: 'Plus Jakarta Sans' }}>Date:</span> {date}
                  </Typography>
                </Box>
              </Grid>

              <Grid item xs={12} md={4}>
                <Box sx={{ p: { xs: 1, md: 2 } }}>
                  <DownloadPdfButton contentRef={contentRef} />
                </Box>
              </Grid>
            </Grid>

            {/* Problem Description Section */}
            <Box
              sx={{
                p: { xs: 2, md: 3 },
                my: { xs: 2, md: 3 },
                borderTop: '2px solid #F4EDEA',
                borderBottom: '2px solid #F4EDEA',
              }}
            >
              <Typography
                style={{
                  color: '#FFA70B',
                  fontFamily: 'Plus Jakarta Sans',
                  fontWeight: '800',
                  fontSize: '16px', // Default font size
                  letterSpacing: '0.2%',
                  lineHeight: '140%',
                  marginBottom: '16px', // Adds space below this text
                }}
              >
                PROBLEM DESCRIPTION
              </Typography>
              <Typography
                style={{
                  fontFamily: 'Plus Jakarta Sans',
                  fontSize: '24px', // Default font size
                  fontStyle: 'normal',
                  fontWeight: '500',
                  lineHeight: '140%',
                  letterSpacing: '0.1%',
                }}
              >
                {problemDescription}
              </Typography>
            </Box>

            {/* Dentist Info Section */}
            <Grid container spacing={2} sx={{ my: { xs: 3, md: 5 } }} alignItems="center">
              <Grid item xs={4} md={4}>
                <Avatar
                  src={dentistImage}
                  alt="Dentist"
                  sx={{
                    width: { xs: 80, sm: 120, md: 200 },
                    height: { xs: 80, sm: 120, md: 200 },
                    margin: '0 auto'
                  }}
                />
              </Grid>
              <Grid item xs={8} md={8}>
                <Typography sx={{ color: '#034641', fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' } }} gutterBottom>
                  DENTIST INFO
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '24px' } }}>
                  <span style={{ fontWeight: '700', color: '#034641', fontFamily: 'Plus Jakarta Sans' }}>Name:</span> <span style={{ fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>Dr. {'Mariam Mohammed.'}</span>
                </Typography>
                <Typography variant="body1" gutterBottom sx={{ fontSize: { xs: '12px', md: '24px' } }}>
                  <span style={{ fontWeight: '700', color: '#034641', fontFamily: 'Plus Jakarta Sans' }}>Title:</span> <span style={{ fontWeight: '500', fontFamily: 'Plus Jakarta Sans' }}>{'General Dentist'}</span>
                </Typography>
              </Grid>
            </Grid>
            <Paper sx={{ p: { xs: 2, md: 4 }, mt: 3, background: '#CFF50526', borderRadius: '15px' }}>
              <Typography sx={{ color: '#FFA70B', fontFamily: 'Plus Jakarta Sans', fontWeight: 'bold', fontSize: { xs: '12px', md: '16px' }, letterSpacing: '0.8px', lineHeight: '140%' }} >
                Dentist Comment
              </Typography>
              <Typography sx={{
                fontFamily: "Plus Jakarta Sans",
                fontSize: { xs: '16px', md: '24px' },
                fontStyle: 'normal',
                fontWeight: '500',
                lineHeight: '140%',
                letterSpacing: '0.48px'
              }}>
                {dentistComment}
              </Typography>
            </Paper>

            {diagnosesArray.map((diagnosis, index) => diagnosis && (
              <Box key={index} sx={{ mb: { xs: 4, md: 6 } }}>
                <Grid container justifyContent="space-between" alignItems="center">
                  <Grid item xs={4} md={7}>
                    <Typography sx={{
                      color: '#FFA70B',
                      fontFamily: 'Satoshi',
                      fontSize: { xs: '18px', md: '36px' },
                      fontWeight: '900',
                      lineHeight: '140%',
                      textAlign: 'left',
                    }} gutterBottom mt={8}>
                      Diagnosis
                    </Typography>
                  </Grid>
                  <Grid item xs={8} md={5} container alignItems={'center'}>
                    <Typography sx={{
                      color: '#034641',
                      fontFamily: 'Satoshi',
                      fontSize: { xs: '18px', md: '36px' },
                      fontWeight: '700',
                      lineHeight: '140%',
                      textAlign: 'right',
                    }} gutterBottom mt={8}>
                      Severity
                    </Typography>
                    <Box sx={{ width: '50%' }} mt={8} ml={2}>
                      <LinearProgress
                        variant="determinate"
                        value={severity[index] * 10}
                        sx={{
                          height: '20px',
                          borderRadius: '10px',
                          '& .MuiLinearProgress-bar': {
                            borderRadius: '10px',
                            backgroundColor: severity[index] <= 3 ? '#81F05A' : severity[index] <= 7 ? '#FFF500' : '#FF0000',
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Paper key={index} sx={{ p: { xs: 3, md: 5 }, mt: 2, borderRadius: '20px', background: '#FFA70B40' }}>
                  <Grid container spacing={3} alignItems={"center"}>

                    <Grid item xs={8} >
                      <Typography sx={{ fontFamily: 'Satoshi', color: '#034641', fontSize: { xs: '18px', md: '36px' }, fontWeight: '700' }} gutterBottom>
                        {diagnosis.name}
                      </Typography>
                      <Typography sx={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontSize: { xs: '12px', md: '24px' },
                        fontStyle: 'normal',
                        fontWeight: '500',
                        lineHeight: '140%',
                      }}>
                        {diagnosis.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Avatar
                        alt="Dentist"
                        src={`data:image/jpeg;base64,${imagesArray[index].image_base}`}
                        sx={{ width: { xs: 90, md: 220 }, height: { xs: 90, md: 220 } }}
                      />
                    </Grid>
                  </Grid>
                  <Typography sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' }, mt: 5, color: '#034641' }} gutterBottom>
                    TREATMENT
                  </Typography>
                  {
                    [...Array(diagnosis.treatmentsNumber)].map((_, i) => {
                      const startIndex = diagnosesArray
                        .slice(0, index)
                        .reduce((totalTreatments, currentDiagnosis) => totalTreatments + currentDiagnosis.treatmentsNumber, 0);
                      const treatmentIndex = startIndex + i;
                      return (
                        <div key={i}>
                          <Typography variant="body1" gutterBottom mt={5} sx={{ fontSize: { xs: '16px', md: '36px' } }}>
                            <span style={{ fontWeight: '700', fontFamily: 'Satoshi' }}>{treatmentsArray[treatmentIndex].Name}</span>
                            <span style={{ color: '#FFA70B', fontWeight: '900', fontFamily: 'Satoshi' }}> {treatmentsArray[treatmentIndex].Cost ? ` Average Cost ${treatmentsArray[treatmentIndex].Cost} AED` : 'Average Cost 0 AED'}</span>
                          </Typography>
                          <Typography sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: { xs: '12px', md: '24px' },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '140%',
                          }}>
                            {treatmentsArray[treatmentIndex].Description}
                          </Typography>
                          {i === diagnosis.treatmentsNumber - 1 && ( // Add disclaimer after last treatment
                            <Typography sx={{
                              fontFamily: 'Plus Jakarta Sans',
                              fontSize: { xs: '10px', md: '14px' },
                              fontStyle: 'italic',
                              color: '#666',
                              mt: 2,
                            }}>
                              * Treatment costs are estimations and may vary between clinics based on individual circumstances and location.
                            </Typography>
                          )}
                        </div>
                      );
                    })
                  }

                  <Typography variant="h5" gutterBottom sx={{ fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: { xs: '12px', md: '16px' }, mt: 5, color: '#034641' }}>
                    RECOMMENDED PRODUCTS
                  </Typography>
                  {
                    [...Array(diagnosis.productsNumber)].map((_, i) => {
                      const startIndex = diagnosesArray
                        .slice(0, index)
                        .reduce((totalProducts, currentDiagnosis) => totalProducts + currentDiagnosis.productsNumber, 0);
                      const productIndex = startIndex + i;

                      return (
                        <div key={i}>
                          <Typography sx={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontSize: { xs: '12px', md: '24px' },
                            fontStyle: 'normal',
                            fontWeight: '500',
                            lineHeight: '140%',
                            mt: '2%'
                          }}>
                            - {productsArray[productIndex].name}
                          </Typography>
                        </div>
                      );
                    })
                  }



                </Paper>
              </Box>
            ))}

            <Grid container pt={5} spacing={2}>
              {/* First Item */}
              <Grid item xs={5} textAlign="center" sx={{ borderRight: '2px solid #F4EDEA' }}>
                <Typography sx={{
                  color: '#AFAFAF',
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: { xs: '16px', md: '24px' },
                }}>
                  <span>UPCOMING DENTAL</span>
                  <br />
                  <span>DISCOUNT PLANS</span>

                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '18px' },
                    color: '#AFAFAF',
                    fontFamily: 'Plus Jakarta Sans',
                    mt: 2,
                  }}
                >
                  <Button
                    variant="text"
                    sx={{
                      color: '#FFA70B', // Sets the text color to the required yellow
                      textDecoration: 'underline',
                      fontWeight: '500',
                      padding: 0, // Remove default padding
                      fontSize: { xs: '14px', md: '18px' },
                      '&:hover': {
                        backgroundColor: 'transparent', // Remove background on hover
                        color: '#FF7000', // Slightly darker color on hover
                      },
                    }}
                    onClick={() => window.location.href = 'http://20.174.1.147/login'}
                  >
                    Click here
                  </Button>
                </Typography>
              </Grid>

              {/* Second Item */}
              <Grid item xs={5} textAlign="center">
                <Typography sx={{
                  color: '#AFAFAF',
                  fontFamily: "Plus Jakarta Sans",
                  fontSize: { xs: '16px', md: '24px' },
                }}>
                  <span>DENTAL</span>
                  <br />
                  <span>DIRECTORY</span>
                </Typography>
                <Typography
                  sx={{
                    fontSize: { xs: '14px', md: '18px' },
                    color: '#AFAFAF',
                    fontFamily: 'Plus Jakarta Sans',
                    mt: 2,
                  }}
                >

                  <Button
                    variant="text"
                    sx={{
                      color: '#FFA70B', // Sets the text color to the required yellow
                      textDecoration: 'underline',
                      fontWeight: '500',
                      padding: 0, // Remove default padding
                      fontSize: { xs: '14px', md: '18px' },
                      '&:hover': {
                        backgroundColor: 'transparent', // Remove background on hover
                        color: '#FF7000', // Slightly darker color on hover
                      },
                    }}
                    onClick={() => window.location.href = 'https://dental.dentinnova.com/directory'}
                  >
                    Click here
                  </Button>
                </Typography>
              </Grid>
            </Grid>
            <Typography variant="body1" align="center" mt={10} sx={{
              color: '#00000080', textAlign: 'center',
              fontFamily: 'Inter',
              fontSize: { xs: '8px', md: '14px' },
              fontWeight: '400',
              lineHeight: '150%',
            }}>
              Please be aware that while our teledental services provide expert guidance, they do not replace in-office dental care.
              By engaging with our services, you agree to our Privacy Policy and Terms of Service, acknowledging the scope and limitations
              of teledental consultations
            </Typography>

            <Typography variant="body2" align="center" sx={{ mt: 3, color: '#00000080', fontSize: { xs: '8px', md: '14px' }, }}>
              <hr />
              © - Copyright - 2023 - Dentinnova, Inc. - All rights reserved
            </Typography>
          </Grid>

        </Grid>
      </Container>
    </div>
  ) : <EmptyPage />;
};
