import { Card, Grid, Typography, Link, ListItem, ListItemIcon, TextField, Button } from '@mui/material';
import logo from '../../static/orangeLogo.png'
import { PhoneOutlined, YouTube, Instagram, X } from '@mui/icons-material';

export const Footer = () => {

    return (

        <>
            <Card sx={{ background: '#B07FE5', borderRadius: '0', p: '5%' }}>
                <Grid container>
                    <Grid item xs={12} md={4.5}>
                        <img src={logo} height={'150px'} width={'300px'}></img>
                        <Typography sx={{ fontFamily: 'Inter', fontSize: '16px', color: 'white' }}>
                            Where Innovation meets Dental Care
                        </Typography>
                    </Grid>
                    <Grid item xs={6} md={2.5} sx={{ display: { xs: 'none', md: 'block' } }}>
                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', color: 'white', fontWeight: '600', mb: '2%', mt: '2%' }}>
                            Company
                        </Typography>
                        <Link sx={{ color: 'white', fontWeight: '300', textDecoration: 'none', mt: '2%', mb: '2%' }} component="button">
                            About
                        </Link> <br />
                        <Link sx={{ color: 'white', fontWeight: '300', textDecoration: 'none', mt: '2%', mb: '2%' }} component="button">
                            Services
                        </Link> <br />
                        <Link sx={{ color: 'white', fontWeight: '300', textDecoration: 'none', mt: '2%', mb: '2%' }} component="button">
                            How it works
                        </Link> <br />
                        <Link sx={{ color: 'white', fontWeight: '300', textDecoration: 'none', mt: '2%', mb: '2%' }} component="button">
                            Benefits
                        </Link> <br />
                    </Grid>
                    <Grid item xs={6} md={2.5}>

                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', color: 'white', fontWeight: '600', mb: '5%', mt: { xs: '10%', md: '1%' } }}>
                            Contact Us
                        </Typography>


                        <ListItem sx={{ pl: '0' }} >
                            <ListItemIcon sx={{ minWidth: 'unset', mr: '2%' }}>

                                <PhoneOutlined sx={{ color: '#fff' }} />

                            </ListItemIcon>
                            <Typography sx={{ color: '#fff' }}>+971-234-2344 </Typography>
                        </ListItem>

                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', color: 'white', fontWeight: '600', mb: '5%', mt: '5%' }}>
                            Follow Us
                        </Typography>
                        <YouTube sx={{ color: 'white', mr: '2%' }} /> <Instagram sx={{ color: 'white', mr: '2%' }} /> <X sx={{ color: 'white', mr: '2%' }} />
                    </Grid>
                    <Grid item xs={6} md={2.5}>

                        <Typography sx={{ fontFamily: 'Plus Jakarta Sans', color: 'white', fontWeight: '600', mb: '5%', mt: { xs: '10%', md: '1%' } }}>
                            Subscribe
                        </Typography>

                        <Typography sx={{ color: 'white', mb: '5%' }}>
                            Get a sample patient report
                        </Typography>


                        <Button sx={{
                            background: '#CFF505', border: '1px solid black', textTransform: 'none', borderRadius: '20px ', color: 'black', '&:hover': {
                                background: '#CFF505',
                            }
                        }} fullWidth>
                            Download
                        </Button>
                    </Grid>
                </Grid>
            </Card>
        </>
    );
}