import React from 'react';
import { Card, CardContent, Typography, Button, styled, Grid } from '@mui/material';
import doctorImage from '../../static/dentist.png';

const RootContainer = styled('div')({
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    backgroundColor: '#fff',
    width:'100%'
});

const CustomCard = styled(Card)({
    backgroundColor: '#F4EDEA',
    position: 'relative',
    overflow: 'visible',
    borderRadius: '10px',
    height: '100%',
    padding: '3%',
    width:'100%'
});

const ContentContainer = styled(CardContent)({
    display: 'flex',
    flexDirection: 'column',
    color: '#000',
    padding: '2%',
    height: '100%',
});

const ActionButton = styled(Button)({
    backgroundColor: '#B07FE5',
    color: '#fff',
    border: '2px solid #000',
    marginTop: '16px',
    textTransform: 'none',
    borderRadius: '20px',

    '&:hover': {
        backgroundColor: '#B07FE5'
    }
});

const DoctorImage = styled('img')({
    position: 'absolute',
    right: '0px',
    bottom: '0px',

});

export const JoinCard = () => {
    return (
        <RootContainer>
            <CustomCard>
                <ContentContainer>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Typography mb={3} style={{ fontFamily: 'Plus Jakarta Sans', fontWeight: '800', fontSize: '30px' }}>Join our Dental List</Typography>
                            <Typography style={{ fontFamily: 'Inter', fontSize: '16px' }}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Quidem culpa expedita saepe omnis repellendus hic ipsum iusto nobis, dignissimos ipsam.</Typography>
                            <ActionButton target="_blank" variant="contained" href="https://www.dentinnova.com">Join Directory</ActionButton> </Grid>
                        <Grid item xs={12} md={6}>
                            <DoctorImage sx={{ width: { xs: '0px', md: '20.25rem' } }} src={doctorImage} alt="Doctor" />

                        </Grid>
                    </Grid>
                </ContentContainer>
            </CustomCard>
        </RootContainer>
    );
};

