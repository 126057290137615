import React, { useState } from 'react';
import { DialogActions, DialogContent, DialogContentText, DialogTitle, AppBar, Dialog, Toolbar, Button, Box, Drawer, List, ListItemButton, ListItemText, IconButton, Typography, TextField, InputAdornment, Grid } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import logo from '../../static/logo2.png';
import { useSearch } from '../context/SearchContext';

const Header = () => {
    const [openDrawer, setOpenDrawer] = useState(false);
    const [searchValue, setSearchValue] = useState('');
    const { updateSearchQuery, updateFilterCondition } = useSearch();
    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleFilterSelection = (distance) => {
        updateFilterCondition(distance);
        handleClose();
    };
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            updateSearchQuery(searchValue);
        }
    };

    return (
        <>
            <AppBar position="static" sx={{ background: '#fff', pr: '5%', pl: '5%', display: { xs: 'none', md: 'block' } }}>
                <Toolbar>
                    <Box sx={{ flexGrow: 1 }}>
                        <img src={logo} alt="Logo" style={{ height: 80 }} />
                    </Box>

                </Toolbar>
            </AppBar>
            <Drawer
                anchor="left"
                open={openDrawer}
                onClose={toggleDrawer}
                sx={{ display: { xs: 'block', md: 'none' } }}
            >
                <Box sx={{ width: 250 }} p={1}>
                    {/* Logo */}
                    <Box sx={{ p: 2, textAlign: 'center' }}>
                        <img src={logo} alt="Logo" style={{ height: 80 }} />
                    </Box>
                    <List>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="About" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Services" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="How It Works" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Benefits" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Dental Directory" />
                        </ListItemButton>
                        <ListItemButton onClick={toggleDrawer}>
                            <ListItemText primary="Get Started" />
                        </ListItemButton>
                    </List>
                    <TextField
                        placeholder="Search by name or tag"
                        value={searchValue}
                        onKeyDown={handleKeyPress}
                        onChange={(e) => setSearchValue(e.target.value)}
                        sx={{ background: '#fff', borderRadius: '10px', border: '3px solid #81C1E1' }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <Button
                                        onClick={
                                            () => {

                                                updateSearchQuery(searchValue);

                                            }
                                        }
                                        sx={{
                                            background: '#F06E5A', fontSize: { xs: '10px', md: '16px' }, color: '#fff', textTransform: 'none', '&:hover': {
                                                background: '#F06E5A'
                                            }, p: '4%', display: { xs: 'none', md: 'block' }
                                        }}>Find a clinic</Button>
                                </InputAdornment>
                            ),
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                    />
                </Box>
            </Drawer>
            <Grid container>
                <Grid item xs={2}>
                    <Box sx={{ display: { xs: 'block', md: 'none' }, position: 'absolute', top: 0, left: 0 }}>
                        <IconButton onClick={toggleDrawer} sx={{ ml: 2 }}>
                            <MenuIcon />
                        </IconButton>
                    </Box>
                </Grid>
                <Grid item xs={8} sx={{ display: { xs: 'block', md: 'none' }, m: '2%' }}>
                    <Button variant="text" onClick={handleClickOpen} sx={{ width: '100%', fontSize: '16px' }}>
                        Filter By Distance
                    </Button>
                    <Dialog open={open} onClose={handleClose}>
                        <DialogTitle>Select Distance</DialogTitle>
                        <DialogContent dividers>
                            <DialogContentText>
                                Choose the maximum distance you want to filter by.
                            </DialogContentText>
                            <Grid container spacing={2}>
                                <Grid item xs={6}>
                                    <Button variant="outlined" size="small" onClick={() => handleFilterSelection('None')}>None</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" size="small" onClick={() => handleFilterSelection(5)}>5 KM</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" size="small" onClick={() => handleFilterSelection(10)}>10 KM</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" size="small" onClick={() => handleFilterSelection(20)}>20 KM</Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" size="small" onClick={() => handleFilterSelection(30)}>30 KM</Button>
                                </Grid>

                            </Grid>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>
                </Grid>
            </Grid>
            <AppBar
                position="static"
                sx={{
                    pr: '5%',
                    pl: '5%',
                    background: '#F4EDEA',
                    pt: '1%',
                    pb: '1%',
                    display: { xs: 'none', md: 'block' },
                }}
            >
                <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start' }}>
                    {/* Filter Section */}
                    <Box>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                                fontFamily: 'Inter',
                                color: '#000',
                                mb: '8px',
                            }}
                        >
                            Filter Distance By
                        </Typography>
                        <Box>
                            <Button
                                onClick={() => {
                                    updateFilterCondition('None');
                                }}
                                sx={{
                                    borderRadius: '20px',
                                    background: '#fff',
                                    color: '#464646',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    letterSpacing: '3%',
                                    fontFamily: 'Inter',
                                    m: '1%',
                                }}
                            >
                                None
                            </Button>
                            <Button
                                onClick={() => {
                                    updateFilterCondition(5);
                                }}
                                sx={{
                                    borderRadius: '20px',
                                    background: '#fff',
                                    color: '#464646',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    letterSpacing: '3%',
                                    fontFamily: 'Inter',
                                    m: '1%',
                                }}
                            >
                                5 KM
                            </Button>
                            <Button
                                onClick={() => {
                                    updateFilterCondition(10);
                                }}
                                sx={{
                                    borderRadius: '20px',
                                    background: '#fff',
                                    color: '#464646',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    letterSpacing: '3%',
                                    fontFamily: 'Inter',
                                    m: '1%',
                                }}
                            >
                                10 KM
                            </Button>
                            <Button
                                onClick={() => {
                                    updateFilterCondition(20);
                                }}
                                sx={{
                                    borderRadius: '20px',
                                    background: '#fff',
                                    color: '#464646',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    letterSpacing: '3%',
                                    fontFamily: 'Inter',
                                    m: '1%',
                                }}
                            >
                                20 KM
                            </Button>
                            <Button
                                onClick={() => {
                                    updateFilterCondition(30);
                                }}
                                sx={{
                                    borderRadius: '20px',
                                    background: '#fff',
                                    color: '#464646',
                                    fontSize: '14px',
                                    fontWeight: 600,
                                    letterSpacing: '3%',
                                    fontFamily: 'Inter',
                                    m: '1%',
                                }}
                            >
                                30 KM
                            </Button>
                        </Box>
                    </Box>

                    {/* Search Section */}
                    <Box sx={{ textAlign: 'left' }}>
                        <Typography
                            sx={{
                                fontSize: '16px',
                                fontWeight: '700',
                                fontFamily: 'Inter',
                                color: '#000',
                                mb: '8px',
                            }}
                        >
                            Search For Nearby Dental Clinics
                        </Typography>
                        <TextField
                            placeholder="Search by name or tag"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            sx={{
                                background: '#fff',
                                borderRadius: '50px', // Circular borders
                                border: '3px solid #000', 
                                p:'1%',
                                width: '300px', 
                                '& .MuiOutlinedInput-root': {
                                    '& fieldset': {
                                        border: 'none', 
                                    },
                                },
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Button
                                            onClick={() => {
                                                updateSearchQuery(searchValue);
                                            }}
                                            sx={{
                                                background: '#CFF505',
                                                color: '#000',
                                                borderRadius: '20px',
                                                textTransform: 'none',
                                                '&:hover': {
                                                    background: '#CFF505',
                                                },
                                            }}
                                        >
                                            Find a clinic
                                        </Button>
                                    </InputAdornment>
                                ),
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Box>
                </Toolbar>
            </AppBar>
        </>
    );
};

export default Header;
